// Common constants for all platforms (Desktop, Web and Mobile) and screens
import config from "config";

export const LogisticsFAIds = [131, 13, 40, 31, 51];

export const buttonType = {
  Apply: "apply",
  FixInterview: "fixinterview",
  CallHR: "callHR"
};

export const defaultProfileImage = `${config.S3_URL}/OlxIntegration/default-profile-image.png`;

export const defaultCompanyImage = `${config.S3_URL}/default_company_image.png`;

export const defaultFAImage = `https://stagingstatic.aasaanjobs.com/media/fa-categories/random_job.svg`;



