// Utils functions common for all platforms (Desktop, Web and Mobile) and screens
// Please don't add function which are dependent on particular platform
import objectPath from "object-path";
import { buttonType } from "screens/common/constants";
import {
  TOTAL_APPLICATION_COUNT,
  GET_SHORT_CANDIDATE_APPLICATION,
  GET_CANDIDATE_APPLICATION
} from "screens/common/queries";
import getData from "utils/getData";
import { clientHelper } from "utils/graphQL";
import { mLog } from "utils/logger";

export const deepGet = (object) => (path) => objectPath.get(object, path);

export const indianSalaryFormatting = (str) => {
  if (str) {
    const num = str.toString().replace(/\D/g, "");
    const otherNumbers = num.substring(0, num.length - 3);
    let lastThree = num.substring(num.length - 3);
    if (otherNumbers !== "") {
      lastThree = `,${lastThree}`;
    }
    return `${otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}${lastThree}`;
  }
  return "";
};

export const distance = (position1, position2) => {
  const lat1 = position1.latitude;
  const lon1 = position1.longitude;
  const lat2 = position2.latitude;
  const lon2 = position2.longitude;
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const deg2rad = (deg) => deg * (Math.PI / 180);

export const getClosestLocation = (referenceLocation, locations) => {
  const newLocations = locations;
  let closest = newLocations[0];
  if (closest.location) {
    const coord = closest.location.split(",");
    closest.latitude = parseFloat(coord[0]);
    closest.longitude = parseFloat(coord[1]);
  }
  let closestDistance = distance(closest, referenceLocation);
  for (let i = 1; i < newLocations.length; i += 1) {
    if (newLocations[i].location) {
      const coord = newLocations[i].location.split(",");
      newLocations[i].latitude = parseFloat(coord[0]);
      newLocations[i].longitude = parseFloat(coord[1]);
    }
    const tempDistance = distance(newLocations[i], referenceLocation);
    if (tempDistance < closestDistance) {
      closestDistance = tempDistance;
      closest = newLocations[i];
      newLocations[i] = newLocations[0];
      newLocations[0] = closest;
    }
  }
  return { locations: newLocations, distance: closestDistance };
};

export const getCleverTapTags = (jobData) => {
  const clevertapTags = [];
  const model = objectPath(jobData);

  const pricingPlanType = model.get("pricing_plan_type") || "";
  const joinings = model.get("application_metrices.joinings");
  const selections = model.get("application_metrices.selections");

  if (joinings || selections) {
    clevertapTags.push("Job Trust Data");
  }
  if (
    pricingPlanType === "PREPAID" ||
    pricingPlanType === "POSTPAID" ||
    pricingPlanType === "TEMP_STAFFING"
  ) {
    clevertapTags.push("Trusted");
  }
  if (pricingPlanType === "JOB_POSTING") {
    clevertapTags.push("Featured");
  }
  return clevertapTags;
};

export const getTagImageProps = (pricingPlanType, isActive) => {
  let tagImageProps = null;

  if (
    pricingPlanType === "PREPAID" ||
    pricingPlanType === "POSTPAID" ||
    pricingPlanType === "TEMP_STAFFING"
  ) {
    tagImageProps = {
      name: "trusted-tag",
      style: {
        height: 17,
        width: 55
      }
    };
  }
  // else if (pricingPlanType === "JOB_POSTING") {
  //   tagImageProps = {
  //     name: "featured-tag",
  //     style: {
  //       height: 13,
  //       width: 65
  //     }
  //   };
  // }
  else if (isActive && pricingPlanType === "FREE") {
    tagImageProps = {
      name: "active-tag",
      style: {
        height: 17,
        width: 62
      }
    };
  }

  return tagImageProps;
};

export const getTotalApplicationCount = async (client) => {
  let applicationCount = null;

  const { data } = await clientHelper("query")(client)({
    query: TOTAL_APPLICATION_COUNT,
    fetchPolicy: "network-only" // Necessary for always using network not cache
  });

  applicationCount = getData(data, "nodes.totalCount", null);

  return applicationCount;
};

export const getCandidateApplication = async (jobId, client, short = true) => {
  let applicationData = null;

  const { data, error } = await clientHelper("query")(client)({
    query: short ? GET_SHORT_CANDIDATE_APPLICATION : GET_CANDIDATE_APPLICATION,
    variables: {
      id: jobId
    }
  });

  if (error) {
    mLog("ERROR in getting application", error);
  } else {
    applicationData = getData(data, "nodes.edges.0", {});
  }

  return applicationData;
};

export const getSourceId = (sources, bType) => {
  if (!sources || !bType) {
    return null;
  }
  if (bType === buttonType.FixInterview) {
    return sources["Walk In Job"] ? sources["Walk In Job"].id : null;
  }
  if (bType === buttonType.CallHR) {
    return sources["Call HR"] ? sources["Call HR"].id : null;
  }
  return -1;
};

export const APPLICATION_HISTORY_ID = {
  // CAP_CAR - applied on client approval required job : trusted job
  TRUSTED_CLIENT_APPROVAL_REQ: 1,
  // CAP_NCAR - applied on client approval not required job : trusted job
  TRUSTED_NO_CLIENT_APPROVAL_REQ: 2,
  // CREATED - Apply through apply click : on any job
  APPLY_GENERAL: 30,
  // UV - Applicaiton created for unverified candidate
  UNVERIFIED_CANDIDATE: 69,
  // Call_HR  - Application created via callHR on any job type
  CALL_HR: 86
};
export const getApplicationHistoryActionId = (jobGet, bType) => {
  if (!jobGet) return undefined;

  let jobGetFun = null;
  if (typeof jobGet === "object" && typeof jobGet !== "function")
    jobGetFun = deepGet(jobGet);
  else jobGetFun = jobGet;

  if (!jobGetFun || typeof jobGetFun !== "function") return undefined;

  const clientApprovalRequired =
    jobGetFun("client_approval_required") ||
    jobGetFun("clientApprovalRequired");
  const isTrustedjobType = isTrustedJob(
    jobGetFun("pricing_plan_type") || jobGetFun("pricingPlanType")
  );
  const unVerifiedCandidate = false;

  if (unVerifiedCandidate) return APPLICATION_HISTORY_ID.UNVERIFIED_CANDIDATE;

  if (bType === buttonType.CallHR) {
    return APPLICATION_HISTORY_ID.CALL_HR;
  }
  if (isTrustedjobType) {
    return clientApprovalRequired
      ? APPLICATION_HISTORY_ID.TRUSTED_CLIENT_APPROVAL_REQ
      : APPLICATION_HISTORY_ID.TRUSTED_NO_CLIENT_APPROVAL_REQ;
  }
  return APPLICATION_HISTORY_ID.APPLY_GENERAL;
};

export const getApplicationMetricesData = (
  applicationMetrices,
  includeOnlyInterviews = false
) => {
  const metricesData = {
    iconName: "",
    iconStyle: {},
    countText: "",
    message: ""
  };

  if (applicationMetrices) {
    const model = objectPath(applicationMetrices);

    const joinings = model.get("joinings");
    const selections = model.get("selections");
    const lastWeekInterviews = model.get("previous_interviews");
    const nextWeekInterviews = model.get("upcoming_interviews");

    let count = 0;

    if (joinings > 0 && !includeOnlyInterviews) {
      count = joinings;
      if (count > 1) {
        metricesData.message = "have joined";
      } else {
        metricesData.message = "has joined";
      }
    } else if (selections > 0 && !includeOnlyInterviews) {
      count = selections;
      if (count > 1) {
        metricesData.message = "have been selected";
      } else {
        metricesData.message = "has been selected";
      }
    } else if (lastWeekInterviews > 0) {
      count = lastWeekInterviews;
      if (count > 1) {
        metricesData.message = "attended interviews last week";
      } else {
        metricesData.message = "has attended interviews last week";
      }
    } else if (nextWeekInterviews > 0) {
      count = lastWeekInterviews;
      if (count > 1) {
        metricesData.message = "are going for interviews next week";
      } else {
        metricesData.message = "is going for interviews next week";
      }
    }

    metricesData.iconStyle.height = 22;

    if (count === 1) {
      metricesData.countText = "1 person";
      metricesData.iconName = "avatar-one";
      metricesData.iconStyle.width = 16;
    } else if (count === 2) {
      metricesData.countText = "2 people";
      metricesData.iconName = "avatar-two";
      metricesData.iconStyle.width = 28;
    } else if (count > 2) {
      metricesData.countText = `${count} people`;
      metricesData.iconName = "avatar-three";
      metricesData.iconStyle.width = 40;
    }
  }

  return metricesData;
};

export const isRecentSlotValid = (recentSlot) => {
  const slot = new Date(recentSlot);
  if (slot instanceof Date) {
    return true;
  }
  return false;
};

export const isWalkInJob = (jobData) => {
  const model = objectPath(jobData);

  const clientApprovalRequired = model.get("client_approval_required");
  const recentSlot = model.get("recent_slot");
  const isScreeningRequired = model.get("is_screening_required");

  if (
    !clientApprovalRequired &&
    !isScreeningRequired &&
    recentSlot &&
    isRecentSlotValid(recentSlot)
  ) {
    return true;
  }
  return false;
};

export const isPaidJob = (pricingPlanType) =>
  ["PREPAID", "POSTPAID", "TEMP_STAFFING"].includes(pricingPlanType);

/**
 * Return boolean true if the pricingPlanType is "PREPAID" OR "POSTPAID" OR "TEMP_STAFFING"
 * case sensitive. similar to isPaidJob.
 * @param {string} pricingPlanType
 * @returns {boolean} true or false
 */
export const isTrustedJob = (pricingPlanType) => isPaidJob(pricingPlanType);

export const showCompleteApplicationProcess = (
  application,
  hasAnsweredMandatoryQuestions
) => {
  const incompleteApplicationEligibilty =
    ["CANDIDATE_APPLIED", "TOBE_SCHEDULED_FOR_INTERVIEW"].includes(
      application.stage
    ) &&
    isPaidJob(application.job && application.job.pricing_plan_type) &&
    !hasAnsweredMandatoryQuestions;

  return (
    incompleteApplicationEligibilty &&
    (application.job.is_screening_required
      ? application.screen_status === "NOT_SCREENED"
      : application.job.client_approval_required === false &&
        !application.recent_slot)
  );
};
