// Common queries for all platforms (Desktop, Web and Mobile) and screens
import gql from "graphql-tag";

export const APPLICATION_FIELDS = `
  id
  display_status_to_candidate
  state
  stage
  latest_working_day
  screen_status
  job {
    id
    title
    latest_question_round
    is_screening_required
    organization {
      id
      name
    }
    client_approval_required
    pricing_plan_type
    functional_area {
      name
      id
    }
    application_metrics {
      joinings
      selections
      previous_interviews
      upcoming_interviews
    }
    offer {
      salary_format
      min_offered_salary
      max_offered_salary
    }
    places {
      place_id
      short_formatted_address
    }
    vertical
  }
  candidate {
    id
    user {
      id
    }
    address {
      id
      locality {
        id
        city {
          id
          name
        }
      }
    }
  }
  recent_slot
  interview {
    id
    date
    type
    location {
      address
      geo_location
      google_place_id
    }
    going_for_interview
    attendance
    type
    start_time
    end_time
  }
  selection {
    salary_offered
    date_of_joining
    joining_status_by_candidate
    date_of_leaving
    monthly_net_salary
  }
  call_hr {
    name
    contact
  }
  job_location {
    place_id
    locality
  }
  created_by {
    id
    user_type
  }
  is_job_outside_candidate_city
`;

export const SHORT_APPLICATION_FIELDS = `
  id
  display_status_to_candidate
  state
  stage
  candidate {
    id
    user {
      id
    }
  }
  job {
    id
    title
    pricing_plan_type
    client_approval_required
    pricing_plan_type
  }
  is_job_outside_candidate_city
`;

export const SIMILAR_JOBS_FIELDS = `
  id
  slug
  title
  designations
  client_approval_required
  recent_slot
  approved_on
  pricing_plan_type
  functional_area {
    id
    name
    id
  }
  organization {
    id
    name
    popular_name
    slug
    is_hr_agency
  }
  tags
  offer {
    salary_format
    min_offered_salary
    max_offered_salary
  }
  locations {
    place {
      place_id
      short_formatted_address
      location
      city
      locality
    }
  }
  expectation {
    work_exp_requirements {
      is_experience_mandatory
      min_experience
      max_experience
    }
  }
  vertical
`;

export const SEARCH_JOB_QUERY = gql`
  query SearchJobs(
    $searchKeyword: String
    $location: String
    $first: Int
    $filter: JobFilter
    $after: Int
  ) {
    searchJobsV2(
      location: $location
      filter: $filter
      first: $first
      query: $searchKeyword
      after: $after
    ) {
      __typename
      totalCount
      edges {
        __typename
        id
        score
        explain
        title
        slug
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
      }
    }
  }
`;

export const SHORT_JOB_APPLY = gql`
  mutation JobApply(
    $id: ID!,
    $referred_by_id:ID,
    $source_id: Int,
    $application_history_action_id: Int,
    $candidate_id: ID,
    $place_id: String,
    $utm: JSON
  ) {
    mutate(type: JobMutation, id: $id) {
      ... on JobMutation {
        id
        apply(
          referred_by_id: $referred_by_id,
          source_id: $source_id,
          application_history_action_id: $application_history_action_id,
          candidate_id: $candidate_id,
          place_id: $place_id,
          utm: $utm
        ) {
          ${SHORT_APPLICATION_FIELDS}
        }
      }
    }
  }
`;

export const JOB_APPLY = gql`
  mutation JobApply(
    $id: ID!,
    $referred_by_id:ID,
    $source_id: Int,
    $application_history_action_id: Int,
    $candidate_id: ID,
    $place_id: String,
    $utm: JSON
  ) {
    mutate(type: JobMutation, id: $id) {
      ... on JobMutation {
        id
        apply(
          referred_by_id: $referred_by_id,
          source_id: $source_id,
          application_history_action_id: $application_history_action_id,
          candidate_id: $candidate_id,
          place_id: $place_id,
          utm: $utm
        ) {
          ${APPLICATION_FIELDS}
        }
      }
    }
  }
`;

export const CALL_HR = gql`
  mutation CallHrInteraction($payload: CallHrInteractionPayload!) {
    CallHrInteraction(payload: $payload) {
      candidate_id
      job_id
      interaction_time
    }
  }
`;

export const TOTAL_APPLICATION_COUNT = gql`
  query {
    nodes(type: Application) {
      totalCount
    }
  }
`;

export const GET_CANDIDATE_APPLICATION = gql`
  query Application($id: ID!) {
    nodes(type: Application, filter: { Application: { job: { id: $id } } }) {
      edges {
        ... on Application {
          ${APPLICATION_FIELDS}
        }
      }
    }
  }
`;

export const GET_SHORT_CANDIDATE_APPLICATION = gql`
  query Application($id: ID!) {
    nodes(type: Application, filter: { Application: { job: { id: $id } } }) {
      edges {
        ... on Application {
          ${SHORT_APPLICATION_FIELDS}
        }
      }
    }
  }
`;

export const SIMILAR_JOBS = gql`
  query SimilarJobs ($id: ID!, $first: Int) {
    similarJobs(jobId: $id, first: $first) {
     ${SIMILAR_JOBS_FIELDS}
    }
  }
`;

export const FIND_SIMILAR_JOBS = gql`
  query SimilarJobs ($id: ID!,  $first: Int) {
    findSimilarJobs(jobId: $id, first:$first) {
    ${SIMILAR_JOBS_FIELDS}
    }
  }
`;

export const KnowledgeBaseQuery = gql`
  query ($kb_type: KBType!) {
    getKnowledgeBase(kb_type: $kb_type) {
      title
      kb_type
      slug
      content
      meta_title
      meta_keywords
      itemprop_name
      itemprop_description
      og_title
      og_description
      twitter_title
      twitter_description
    }
  }
`;

export const OKAYGO_GIG_JOBS_FIELDS = `
  content {
    jobId
    logo
    jobStatus
    jobRole
    partnerName
    partnerId
    companyName
    hubAddress
    hubName
    city
    minSalary
    maxSalary
    salaryType
    shiftStartTime
    shiftEndTime
    distance
    distanceCluster
    shiftHours
    earningPerHour
    selectionStatus
    isApplied
    noOfOpenings
    closingDate
    jobType
    singleDayDate
    minimum_guarantee
    earning_per_day
    per_packet_earning
    mg_blob
    ppe_blob
    payout_type
    mg_blob_hi
    ppe_blob_hi
    document_name
    hubLatitude
    hubLongitude
    interviewStartTime
    interviewEndTime
    interviewLocation
    insertedOn
  }
  pageable {
    sort {
      sorted
      unsorted
      empty
    }
    pageNumber
    pageSize
    offset
    paged
    unpaged
  }
  last
  totalElements
  totalPages
  numberOfElements
  first
  size
  number
  empty
`;

export const OKAY_GO_GIG_JOBS = gql`
  query OkayGoGigJobs ($first: Int) {
    getOkayGoJobLists(first:$first) {
      ${OKAYGO_GIG_JOBS_FIELDS}
    }
  }
`;

export const SEARCH_GIG_JOB_QUERY = gql`
  query getGigJobList($pageNo: Int, $rows: Int) {
    getOkayGoJobLists(page_no: $pageNo, rows: $rows) {
      content {
        jobId
        jobStatus
        jobRole
        partnerName
        partnerId
        companyName
        hubAddress
        hubName
        city
        minSalary
        maxSalary
        salaryType
        shiftStartTime
        shiftEndTime
        distance
        distanceCluster
        shiftHours
        earningPerHour
        selectionStatus
        isApplied
        noOfOpenings
        closingDate
        jobType
        singleDayDate
        minimum_guarantee
        earning_per_day
        per_packet_earning
        hubLatitude
        hubLongitude
        interviewStartTime
        interviewEndTime
        interviewLocation
        insertedOn
      }
      totalElements
      totalPages
    }
  }
`;
